html,
body {
	margin: 0; /* 기본 여백 제거 */
	padding: 0; /* 기본 패딩 제거 */
	height: 100%; /* 부모 요소가 100% 높이를 가지도록 설정 */
	display: flex;
	flex-direction: column;
	-webkit-text-size-adjust: 100%; /* iOS에서 자동 텍스트 크기 조정 방지 */
	text-size-adjust: 100%;
}

#root {
	height: 100%; /* React 애플리케이션이 화면 전체를 차지하도록 설정 */
}

@font-face {
	font-family: 'Black';
	src: url('assets/fonts/Pretendard-Black.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Bold';
	src: url('assets/fonts/Pretendard-Bold.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'ExtraBold';
	src: url('assets/fonts/Pretendard-ExtraBold.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'ExtraLight';
	src: url('assets/fonts/Pretendard-ExtraLight.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Light';
	src: url('assets/fonts/Pretendard-Light.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Medium';
	src: url('assets/fonts/Pretendard-Medium.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Regular';
	src: url('assets/fonts/Pretendard-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'SemiBold';
	src: url('assets/fonts/Pretendard-SemiBold.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Thin';
	src: url('assets/fonts/Pretendard-Thin.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
